<template>
  <div>
    <form
      ref="form"
      class="form-auth-forgot-password"
      @submit.prevent="onSubmit"
      @keydown.enter.prevent="onSubmit"
    >
      <ValidationObserver ref="validation">
        <ValidationProvider
          vid="form.email"
          rules="required|email"
          v-slot="{ errors }"
        >
          <DgPortalInput
            v-model="form.email"
            ref="EMAIL"
            name="EMAIL"
            type="email"
            :placeholder="$t('PORTAL.AUTH.EMAIL')"
            autofocus
            variant="outline"
            prepend-icon="Mail"
            prepend-icon-color="#4B4B4E"
            :error="errors[0]"
          />
        </ValidationProvider>
      </ValidationObserver>
      <DgPortalButton
        type="submit"
        :portalColor="portalSettings.portalColor"
        :label="$t('RESET_YOUR_PASSWORD')"
        :loading="loading"
        size="sm"
        full-width
      />
    </form>
    <div>
      <p
        class="form-auth-forgot-password__have-already-account"
        :style="additionaInfoStyle"
      >
        <span>{{ $t("HAVE_ALREADY_ACCOUNT") + " " }}</span>
        <DgLink tag="router-link" :to="loginPath" :label="$t('SIGN_IN')" />
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { fetchResetPassword } from "@/service/auth";
import { DgPortalInput, DgPortalButton, DgLink } from "@engenharia/dashgoo-ui";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "PageAuthForgotPassword",
  components: {
    DgLink,
    DgPortalInput,
    DgPortalButton,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    form: {
      email: "",
    },
    loading: false,
    additionaInfoStyle: null,
    root: null,
  }),
  mounted() {
    const color =
      this.portalSettings?.portalColor === "#FFFFFF"
        ? "#000000"
        : this.portalSettings.portalColor;
    this.form.email = "";
    this.additionaInfoStyle = {
      "--forgot-password-link-color": color,
    };
    this.root = document.documentElement;
    this.root.style.setProperty("--swal-alert-confirm-button-color", color);
  },
  computed: {
    ...mapState({
      portalSettings: (state) => state.portal?.settings,
    }),
    loginPath() {
      const pathFields = this.$route.path.split("/");
      pathFields.pop();
      return pathFields.join("/");
    },
  },
  methods: {
    async onSubmit() {
      const formIsValid = this.$refs.validation
        ? await this.$refs.validation.validate()
        : true;

      if (!formIsValid) return;

      try {
        this.loading = true;
        const form = this.form;
        await fetchResetPassword(form);
      } finally {
        this.$notifySuccess({
          title: "SUCCESS",
          text: "CHECK_YOUR_EMAIL",
          callback: (isConfirm) => {
            if (isConfirm) {
              this.form.email = "";
            }
          },
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.swal2-container.swal2-center > .swal2-popup {
  border-radius: 40px;
}
.form-auth-forgot-password__have-already-account {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #525252;
  & .dg-link {
    font-family: inherit;
    font-style: inherit;
    font-weight: 600;
    font-size: inherit;
    line-height: inherit;
    text-decoration-line: underline;
    color: var(--forgot-password-link-color) !important;
  }
}
</style>
